import React from "react";

const data =  [
        {
          "img": "assets/img/testimonials/04.jpg",
          "text": "\"Great application, made eating out really easy and informative.\"",
          "name": "Charlie Smith"
        },
        {
          "img": "assets/img/testimonials/05.jpg",
          "text": "\"I went on a trip, and this app made it easy to find the best places to eat and what to order.\"",
          "name": "Brian Clark"
        },
        {
          "img": "assets/img/testimonials/06.jpg",
          "text": "\" I love leaving reviews for restaurants and seeing what other peoples are eating, it also makes it a lot easier to figure out the specialities of different restaurants. \"",
          "name": "Jane Olive"
        }
      ];

export default function Features() {
  return (
    
  <div className="container mx-auto px-4">
    <div className="items-center flex flex-wrap">
      <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
        <img
          alt="..."
          className="max-w-full rounded-lg shadow-lg"
          src="https://images.unsplash.com/photo-1555212697-194d092e3b8f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
        />
      </div>
      <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
        <div className="md:pr-12">
          <div >
            
            <img
              alt="..."
              src={require("assets/img/brand_logo.png").default}
              className="  max-w-full mx-auto"
              style={{ maxWidth: "250px" }}
            />
          </div>
          <h3 className="text-3xl font-semibold">
            Boast your Productivity with EveMail
          </h3>
          <p className="mt-4 text-lg leading-relaxed text-gray-600">
            The Plugin software gives you the ability to automate, writing replies, send information and schedule meetings allowing you to optimize your schedule
          </p>
          <p className="mt-4 text-lg leading-relaxed text-gray-600">
            Steps:
          </p>
          <ul className="list-none mt-6">
            <li className="py-2">
              <div className="flex items-center">
                <div>
                <span className="text-s font-semibold inline-block py-2 px-2 uppercase rounded-full text-blue-600 bg-blue-200 mr-3">
                    1.
                  </span>
                </div>
                <div>
                  <h3 className="text-gray-600">
                    Sign Up on the Website
                  </h3>
                </div>
              </div>
            </li>
            <li className="py-2">
              <div className="flex items-center">
                <div>
                <span className="text-s font-semibold inline-block py-2 px-2 uppercase rounded-full text-blue-600 bg-blue-200 mr-3">
                    2.
                  </span>
                </div>
                <div>
                  <h3 className="text-gray-600">
                    Add Your Email Account
                  </h3>
                </div>
              </div>
            </li>
            <li className="py-2">
              <div className="flex items-center">
                <div>
                  <span className="text-s font-semibold inline-block py-2 px-2 uppercase rounded-full text-blue-600 bg-blue-200 mr-3">
                    3.
                  </span>
                </div>
                <div>
                  <h3 className="text-gray-600">
                    Configure your account information, templates and schedule options 
                  </h3>
                </div>
              </div>
            </li>
            <li className="py-2">
              <div className="flex items-center">
                <div>
                  <span className="text-s font-semibold inline-block py-2 px-2 uppercase rounded-full text-blue-600 bg-blue-200 mr-3">
                    4.
                  </span>
                </div>
                <div>
                  <h3 className="text-gray-600">
                    Let Eve reduce your workload!
                  </h3>
                </div>
              </div>
            </li>

            <li className="py-2 justify-center align-center">
              <div className="flex items-center"> 
                <div className="justify-center align-center max-w-full mx-auto py-4" >
                  <a
                      href="https://app.evemail.one/"
                      className={ 
                        "bg-blue-500 text-white active:bg-blue-600" + " text-m font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-2 lg:mb-0 ml-3 mb-3 mt=5"
                      }
                  >
                      Sign Up for Free
                  </a> 
                </div>
              </div>
            </li>
            
          </ul>

          
          
        </div>
        
        
      </div>
      
    </div>
  </div>
    
  );
}

/*
 <div className="flex flex-wrap">
    <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
      <div className="px-6">
        <img
          alt="..."
          src={require("assets/img/team-1-800x800.jpg").default}
          className="shadow-lg rounded-full max-w-full mx-auto"
          style={{ maxWidth: "120px" }}
        />
        <div className="pt-6 text-center">
          <h5 className="text-xl font-bold">
            Ryan Tompson
          </h5>
          <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
            Web Developer
          </p>
          <div className="mt-6">
            <button
              className="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-twitter"></i>
            </button>
            <button
              className="bg-blue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-facebook-f"></i>
            </button>
            <button
              className="bg-blue-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-dribbble"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
      <div className="px-6">
        <img
          alt="..."
          src={require("assets/img/team-2-800x800.jpg").default}
          className="shadow-lg rounded-full max-w-full mx-auto"
          style={{ maxWidth: "120px" }}
        />
        <div className="pt-6 text-center">
          <h5 className="text-xl font-bold">
            Romina Hadid
          </h5>
          <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
            Marketing Specialist
          </p>
          <div className="mt-6">
            <button
              className="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-google"></i>
            </button>
            <button
              className="bg-blue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-facebook-f"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
      <div className="px-6">
        <img
          alt="..."
          src={require("assets/img/team-3-800x800.jpg").default}
          className="shadow-lg rounded-full max-w-full mx-auto"
          style={{ maxWidth: "120px" }}
        />
        <div className="pt-6 text-center">
          <h5 className="text-xl font-bold">
            Alexa Smith
          </h5>
          <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
            UI/UX Designer
          </p>
          <div className="mt-6">
            <button
              className="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-google"></i>
            </button>
            <button
              className="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-twitter"></i>
            </button>
            <button
              className="bg-gray-800 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-instagram"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
      <div className="px-6">
        <img
          alt="..."
          src={require("assets/img/team-4-470x470.png").default}
          className="shadow-lg rounded-full max-w-full mx-auto"
          style={{ maxWidth: "120px" }}
        />
        <div className="pt-6 text-center">
          <h5 className="text-xl font-bold">
            Jenna Kardi
          </h5>
          <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
            Founder and CEO
          </p>
          <div className="mt-6">
            <button
              className="bg-blue-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-dribbble"></i>
            </button>
            <button
              className="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-google"></i>
            </button>
            <button
              className="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-twitter"></i>
            </button>
            <button
              className="bg-gray-800 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-instagram"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
*/