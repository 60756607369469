import React from "react";

const data =  [
        {
          "img": "assets/img/testimonials/04.jpg",
          "text": "\"Great application, made eating out really easy and informative.\"",
          "name": "Charlie Smith"
        },
        {
          "img": "assets/img/testimonials/05.jpg",
          "text": "\"I went on a trip, and this app made it easy to find the best places to eat and what to order.\"",
          "name": "Brian Clark"
        },
        {
          "img": "assets/img/testimonials/06.jpg",
          "text": "\" I love leaving reviews for restaurants and seeing what other peoples are eating, it also makes it a lot easier to figure out the specialities of different restaurants. \"",
          "name": "Jane Olive"
        }
      ];

export default function Features() {
  return (
    <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i className="fas fa-award"></i>
                    </div>
                    <h6 className="text-xl font-semibold">User Templates </h6>
                    <p className="mt-2 mb-4 text-gray-600">

                      Using pre-defined templates be able to automate responses to customers and others.
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                      <i className="fas fa-retweet"></i>
                    </div>
                    <h6 className="text-xl font-semibold">
                      Automate Repetitive Loop
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Reduce the amount of time spent manually monitoring emails to ensure clients request and inquiries aren't missed.
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                      <i className="fas fa-calendar-plus"></i>
                    </div>
                    <h6 className="text-xl font-semibold">
                      Scheduling  
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Get rid of back and forth messages with people to book meetings, rather automate the process via a digital assistant.
                    </p>
                  </div>
                </div>
              </div>
            </div>


            <div className="flex flex-wrap items-center mt-32">
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                  <i className="fas fa-user-friends text-xl"></i>
                </div>
                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                  Why Use Us
                </h3>
                <p className="text-lg  leading-relaxed mt-4 mb-4 text-gray-700"> 
                  Did you know that the average working professional spends roughly 28% of their time reading and responding to emails? 
                  This percentage rises even higher for companies that provide highly tailored and specialized services to their customers.
                  The average full-time worker spending 28% of their workday on emails equates to 2.6 hours out of an 8-hour workday, 
                  roughly replying to 120 emails every day. You have a lot more to do than reply to emails and we are here to take care of
                  that for you!

                </p>
                <p className="text-lg leading-relaxed mt-0 mb-4 text-gray-700">
                   <b>All of this for $20/Month in a premium account !</b>
                  
                </p>
                <a
                  href="https://app.evemail.one/"
                  className={ "bg-blue-500 text-white active:bg-blue-600" +
                  " text-m font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"
                }
                >
                  Check out the Plugin
                </a> 
              </div>

              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-blue-600">
                  <img
                    alt="..."
                    src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1051&q=80"
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative p-8 mb-4">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 583 95"
                      className="absolute left-0 w-full block"
                      style={{
                        height: "95px",
                        top: "-94px"
                      }}
                    >
                      <polygon
                        points="-30,95 583,95 583,65"
                        className="text-blue-600 fill-current"
                      ></polygon>
                    </svg>
                    <h4 className="text-xl font-bold text-white">
                      Top Notch Services
                    </h4>
                    <p className="text-md font-light mt-2 text-white">
                    EveMail provides you with personalized email templates, regular automated follow-ups, 
                    automatic scheduling based on vacancies in your calendar, and innovative and visually appealing 
                    templates for users to customize their communication with clients. All of this for just $20 a month!

                    </p>
                  </blockquote>
                </div>
              </div>

            </div>
          </div>
    
  );
}

/*
 <div className="flex flex-wrap">
    <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
      <div className="px-6">
        <img
          alt="..."
          src={require("assets/img/team-1-800x800.jpg").default}
          className="shadow-lg rounded-full max-w-full mx-auto"
          style={{ maxWidth: "120px" }}
        />
        <div className="pt-6 text-center">
          <h5 className="text-xl font-bold">
            Ryan Tompson
          </h5>
          <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
            Web Developer
          </p>
          <div className="mt-6">
            <button
              className="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-twitter"></i>
            </button>
            <button
              className="bg-blue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-facebook-f"></i>
            </button>
            <button
              className="bg-pink-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-dribbble"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
      <div className="px-6">
        <img
          alt="..."
          src={require("assets/img/team-2-800x800.jpg").default}
          className="shadow-lg rounded-full max-w-full mx-auto"
          style={{ maxWidth: "120px" }}
        />
        <div className="pt-6 text-center">
          <h5 className="text-xl font-bold">
            Romina Hadid
          </h5>
          <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
            Marketing Specialist
          </p>
          <div className="mt-6">
            <button
              className="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-google"></i>
            </button>
            <button
              className="bg-blue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-facebook-f"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
      <div className="px-6">
        <img
          alt="..."
          src={require("assets/img/team-3-800x800.jpg").default}
          className="shadow-lg rounded-full max-w-full mx-auto"
          style={{ maxWidth: "120px" }}
        />
        <div className="pt-6 text-center">
          <h5 className="text-xl font-bold">
            Alexa Smith
          </h5>
          <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
            UI/UX Designer
          </p>
          <div className="mt-6">
            <button
              className="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-google"></i>
            </button>
            <button
              className="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-twitter"></i>
            </button>
            <button
              className="bg-gray-800 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-instagram"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
      <div className="px-6">
        <img
          alt="..."
          src={require("assets/img/team-4-470x470.png").default}
          className="shadow-lg rounded-full max-w-full mx-auto"
          style={{ maxWidth: "120px" }}
        />
        <div className="pt-6 text-center">
          <h5 className="text-xl font-bold">
            Jenna Kardi
          </h5>
          <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
            Founder and CEO
          </p>
          <div className="mt-6">
            <button
              className="bg-pink-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-dribbble"></i>
            </button>
            <button
              className="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-google"></i>
            </button>
            <button
              className="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-twitter"></i>
            </button>
            <button
              className="bg-gray-800 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-instagram"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
*/