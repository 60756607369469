import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";

import Landing from "views/Landing.js";
import PrivacyPolicy from "components/privacyPolicy";

import TermsAndCond from "components/TermsAndCond"

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <div >
        <Route exact path='/' component={Landing} /> 
        <Route exact path='/PrivacyPolicy' component={PrivacyPolicy} />
        <Route exact path='/TermsAndConditions' component={TermsAndCond} />
      </div>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
